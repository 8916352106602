.video-player-container{
  width: 100vw;
  height: 100vh;
}
.video-player-video{
  width: 1px;
  height: 1px;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.video-container{
  border: 2px solid red !important; 
  width: 100%;
  height: 100%;
}